// export const base_url = `http://localhost:4000`;
// export const uploads_url = `http://localhost:4000/uploads/`;
// export const api_url = `http://localhost:4000/api`;

//
    // export const base_url = `https://dashboard.jooodah.com`;
    // export const uploads_url = `https://dashboard.jooodah.com/uploads/`;
    // export const api_url = `https://dashboard.jooodah.com/api`;

export const base_url = `https://salesdashboard.jooodah.com`;
export const uploads_url = `https://salesdashboard.jooodah.com/uploads/`;
export const api_url = `https://salesdashboard.jooodah.com/api`;

export const sales_dashboard_url = `https://salesdashboard.jooodah.com`;
export const jooodah_dashboard_url = `https://dashboard.jooodah.com`;
